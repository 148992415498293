<template>
  <div class="page-container reject-reason-list">
    <div class="page-title-container">
      <h1 class="page-title">Auditoría</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar registros</div>
              </button>

              <div class="d-flex d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold mr-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>

                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold mr-0"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="downloadReport()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>

              <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold ml-0"
                  v-b-tooltip.hover
                  title="Descargar"
                  v-if="$can('manage', 'auditoria_descarga_excel')"
                  @click="downloadReport()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por usuario, descripción"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="mb-0">
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Fecha y hora</span>
            </div>
            <div>
              <span>Usuario</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span v-local-date:hour="o.createdAt"></span>
                </div>

                <div>
                  <span>{{ o.actor }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Acción:</span
                    >
                    <span :class="`badge ${getAction(o.action).color}`">{{
                      getAction(o.action).label
                    }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Descripción:</span
                    >
                    <span>{{ o.description }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>Acción</th>
              <th>Fecha y hora</th>
              <th>Usuario</th>
              <th>Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="cell-center pl-3">
                <span :class="`badge ${getAction(o.action).color}`">{{
                    getAction(o.action).label
                  }}</span>
              </td>
              <td class="cell-center pl-3" v-local-date:hour="o.createdAt"></td>
              <td class="cell-center px-4">{{ o.actor }}</td>
              <td class="pl-5">{{ o.description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  FilterMenu,
  FilterMixin,
  PaginationMixin,
  SearchBar,
  Util,
} from "wize-admin";
import fileDownload from "js-file-download"

import { Constants as LocalConstants } from "@/core/utils";
import { AuditLogService } from "@/core/services";

export default {
  components: {
    SearchBar,
    FilterMenu,
  },
  data() {
    return {
      list: [],
      selectedRejectReason: null,
      tooltipType: null,
      rejectReasons: [],
      selectedPaymentId: null,
      filterOptions: [
        {
          label: "Acción",
          key: "action",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.AuditLogAction.CREATE,
            LocalConstants.AuditLogAction.UPDATE,
            LocalConstants.AuditLogAction.DELETE,
          ],
        },
        {
          label: "Fecha",
          key: "date",
          type: Constants.TypeFilters.DATE,
          isRange: true,
        },
      ],
    };
  },
  mixins: [FilterMixin, PaginationMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize,
        };
        const data = { search: this.search, ...this.filters };
        const response = await AuditLogService.list(data, params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getAction(key) {
      return Util.searchValue(key, LocalConstants.AuditLogAction);
    },
    async downloadReport() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        const response = await AuditLogService.download(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    }
  },
  async mounted() {
    await this.loadData(this.page, this.pageSize);
  },
};
</script>

<style lang="stylus" scoped></style>
