import ConfigView from "../ConfigView.vue";
import PusherEditor from "../Pages/PusherEditor.vue";
import PusherList from "../Pages/PusherList.vue";
import PusherDetails from "../Pages/PusherDetails.vue";
import RejectReasonEditor from "../Pages/RejectReasonEditor.vue";
import RejectReasonList from "../Pages/RejectReasonList.vue";
import ProjectTypeEditor from "../Pages/ProjectTypeEditor.vue";
import ProjectTypeList from "../Pages/ProjectTypeList.vue";
import ZonalEditor from "../Pages/ZonalEditor.vue";
import ZonalList from "../Pages/ZonalList.vue";
import ExpenseLineEditor from "../Pages/ExpenseLineEditor.vue";
import ExpenseLineList from "../Pages/ExpenseLineList.vue";

const routes = [
  {
    path: '/mantenimiento',
    component: ConfigView,
    name: 'config',
    children: [
      {
        path: 'impulsador',
        name: 'config-pusher-list',
        roles: ['mantenimiento_impulsadores_lista'],
        component: PusherList,
      },
      {
        path: 'impulsador/crear',
        name: 'config-pusher-create',
        roles: ['mantenimiento_impulsadores_agregar'],
        component: PusherEditor,
      },
      {
        path: 'impulsador/:pusherId',
        name: 'config-pusher-edit',
        roles: ['mantenimiento_impulsadores_editar'],
        component: PusherEditor
      },
      {
        path: 'impulsador/:pusherId/detalle',
        name: 'config-pusher-details',
        roles: ['mantenimiento_impulsadores_detalle'],
        component: PusherDetails
      },
      {
        path: 'motivo-rechazo',
        name: 'config-reject-reason-list',
        roles: ['mantenimiento_motivos_rechazo_lista'],
        component: RejectReasonList
      },
      {
        path: 'motivo-rechazo/crear',
        name: 'config-reject-reason-create',
        roles: ['mantenimiento_motivos_rechazo_agregar'],
        component: RejectReasonEditor
      },
      {
        path: 'motivo-rechazo/:id',
        name: 'config-reject-reason-edit',
        roles: ['mantenimiento_motivos_rechazo_editar'],
        component: RejectReasonEditor
      },
      {
        path: 'proyecto',
        name: 'config-project-type-list',
        roles: ['mantenimiento_proyectos_lista'],
        component: ProjectTypeList
      },
      {
        path: 'proyecto/crear',
        name: 'config-project-type-create',
        roles: ['mantenimiento_proyectos_agregar'],
        component: ProjectTypeEditor
      },
      {
        path: 'proyecto/:id',
        name: 'config-project-type-edit',
        roles: ['mantenimiento_proyectos_editar'],
        component: ProjectTypeEditor
      },
      {
        path: 'linea-gasto',
        name: 'config-expense-line-list',
        roles: ['mantenimiento_lineas_gasto_lista'],
        component: ExpenseLineList
      },
      {
        path: 'linea-gasto/crear',
        name: 'config-expense-line-create',
        roles: ['mantenimiento_lineas_gasto_agregar'],
        component: ExpenseLineEditor
      },
      {
        path: 'linea-gasto/:id',
        name: 'config-expense-line-edit',
        roles: ['mantenimiento_lineas_gasto_editar'],
        component: ExpenseLineEditor
      },
      {
        path: 'zonal',
        name: 'config-zonal-list',
        roles: ['mantenimiento_zonales_lista'],
        component: ZonalList
      },
      {
        path: 'zonal/crear',
        name: 'config-zonal-create',
        roles: ['mantenimiento_zonales_agregar'],
        component: ZonalEditor
      },
      {
        path: 'zonal/:id',
        name: 'config-zonal-edit',
        roles: ['mantenimiento_zonales_editar'],
        component: ZonalEditor
      },
    ]
  },
]

export default routes
