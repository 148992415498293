<template>
  <div class="page-container payments-batch-list">
    <div class="page-title-container">
      <h1 class="page-title">Generar IDs de pago</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar cargas</div>
              </button>

              <div class="d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por identificador o periodo"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />

              <div class="text-right mt-2 mt-md-0">
                <b-button
                  :to="{ name: 'payments-batch-upload' }"
                  variant="none"
                  class="button button-primary ml-md-2"
                  v-if="$can('manage', 'pagos_generar_carga')"
                  >Cargar</b-button
                >
              </div>
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="mb-0">
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Fecha de carga</span>
            </div>
            <div>
              <span>Identificador</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span v-local-date="o.uploadDate"></span>
                </div>

                <div>
                  <span>{{ o.identifier }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3">
                <a href="" class="mr-2" @click="approve(o.id)" v-if="o.status === 1 && $can('manage', 'pagos_generar_aprobar')"
                
                  >Aprobar</a
                >

                <a href="" @click="download(o.file)" v-if="$can('manage', 'pagos_generar_descargar')">Descargar archivo</a>
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Proyecto:</span
                    >
                    <span>{{ o.projectTypePrefix }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Periodo:</span
                    >
                    <span>{{ o.period }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Cantidad de pagos:</span
                    >
                    <span>{{ o.paymentsCount }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Valor total de carga:</span
                    >
                    <span v-decimal:pen="o.valueSum"></span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Cargado por:</span
                    >
                    <span>{{ o.createdBy }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Aprobado por:</span
                    >
                    <span>{{ o.approver }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Estado:</span
                    >
                    <span :class="`badge ${getStatus(o.status).color}`">{{
                      getStatus(o.status).label
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th class="pl-2">Fecha de carga</th>
              <th>Identificador</th>
              <th class="px-2">Proyecto</th>
              <th>Periodo</th>
              <th class="px-2">
                Cantidad <br />
                de pagos
              </th>
              <th>
                Valor total <br />
                de carga
              </th>
              <th>Cargado por</th>
              <th>Aprobado por</th>
              <th>Estado</th>
              <th class="pr-md-3" v-if="$can('manage', 'pagos_generar_aprobar')
                 || $can('manage', 'pagos_generar_descargar')">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="cell-center" v-local-date="o.uploadDate"></td>
              <td class="cell-center">{{ o.identifier }}</td>
              <td class="cell-center px-2">{{ o.projectTypePrefix }}</td>
              <td class="cell-center">{{ o.period }}</td>
              <td class="cell-center" v-int="o.paymentsCount"></td>
              <td class="cell-center" v-decimal:pen="o.valueSum">
                {{ o.valueSum }}
              </td>
              <td class="px-2">{{ o.createdBy }}</td>
              <td>{{ o.approver }}</td>
              <td class="cell-center pl-md-2">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>
              <td class="cell-center d_option pr-md-2 position-relative" 
                v-if="$can('manage', 'pagos_generar_aprobar') || $can('manage', 'pagos_generar_descargar')">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Aprobar"
                      @click="approve(o.id)"
                      v-if="o.status === 1 && $can('manage', 'pagos_generar_aprobar')"
                    />
                    <DropdownItem
                      text="Descargar archivo"
                      v-if="$can('manage', 'pagos_generar_descargar')"
                      @click="download(o.file)"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  Constants,
  DropdownItem,
  DropdownTable,
  FilterMenu,
  FilterMixin,
  PaginationMixin,
  SearchBar,
  Util,
} from "wize-admin";

import { PaymentBatchService } from "@/core/services";
import { Constants as LocalConstants } from "@/core/utils";

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    FilterMenu,
  },
  data() {
    return {
      list: [],
      tooltipType: null,
      filterOptions: [
        {
          label: "Fecha de carga",
          key: "upload",
          type: Constants.TypeFilters.DATE,
          isRange: true,
        },
      ],
    };
  },
  mixins: [PaginationMixin, FilterMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "createdAt,desc",
        };

        const data = { search: this.search, ...this.filters };
        const response = await PaymentBatchService.list(data, params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.PaymentBatchStatus);
    },
    approve(batchId) {
      const self = this;
      Alert.dialog("¿Desea aprobar la carga?", null, async function () {
        try {
          await self.$store.dispatch("app/loading", true);
          await PaymentBatchService.changeStatus(batchId, {
            status: LocalConstants.PaymentBatchStatus.APPROVED.key,
          });
          self.loadData(this.page, this.pageSize);
          Alert.success("Carga aprobada correctamente");
        } catch (e) {
          console.error(e);
          Alert.error(e.errors.message);
        } finally {
          await self.$store.dispatch("app/loading", false);
        }
      });
    },
    async download(asset) {
      try {
        if (asset) {
          this.$store.dispatch("app/loading", true);

          await Util.downloadFile(asset.url, asset.name);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  mounted() {
    this.loadData(this.page, this.pageSize);
  },
};
</script>

<style lang="stylus" scoped></style>
