<template>
  <div class="page-container expense-line-list">
    <div class="page-title-container">
      <h1 class="page-title">Líneas de gasto</h1>
    </div>
    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar líneas de gasto</div>
              </button>

              <div class="d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por descripción"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />

              <div class="text-right mt-2 mt-md-0">
                <b-button
                  :to="{ name: 'config-expense-line-create' }"
                  variant="none"
                  class="button button-primary ml-md-2"
                  v-if="$can('manage', 'mantenimiento_lineas_gasto_agregar')"
                  >Agregar</b-button
                >
              </div>
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="mb-0">
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Descripción</span>
            </div>

            <div></div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span>{{ o.description }}</span>
                </div>

                <div>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3">
                <router-link
                  :to="{
                    name: 'config-expense-line-edit',
                    params: { id: o.id },
                  }"
                  class="mr-2"
                  v-if="$can('manage', 'mantenimiento_lineas_gasto_editar')"
                >
                  Editar
                </router-link>

                <a
                  href=""
                  @click.prevent="
                    changeStatus(o.id, ExpenseLineStatus.INACTIVE.key)
                  "
                  v-if="$can('manage', 'mantenimiento_lineas_gasto_suspender') &&
                      o.status === ExpenseLineStatus.ACTIVE.key"
                  >Suspender</a
                >

                <a
                  href=""
                  @click.prevent="
                    changeStatus(o.id, ExpenseLineStatus.ACTIVE.key)
                  "
                  v-if="$can('manage', 'mantenimiento_lineas_gasto_suspender') &&
                    o.status === ExpenseLineStatus.INACTIVE.key"
                  >Activar</a
                >
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Estado:</span
                    >
                    <span :class="`badge ${getStatus(o.status).color}`">{{
                      getStatus(o.status).label
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th class="pl-md-3">Descripción</th>
              <th>Estado</th>
              <th class="pr-md-3"
              v-if="$can('manage', 'mantenimiento_lineas_gasto_editar') &&
                  $can('manage', 'mantenimiento_lineas_gasto_suspender')">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="pl-3">{{ o.description }}</td>
              <td class="cell-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>
              <td class="cell-center d_option pr-md-2 position-relative"
                v-if="$can('manage', 'mantenimiento_lineas_gasto_editar') &&
                  $can('manage', 'mantenimiento_lineas_gasto_suspender')">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Editar"
                      v-if="$can('manage', 'mantenimiento_lineas_gasto_editar')"
                      :to="{
                        name: 'config-expense-line-edit',
                        params: { id: o.id },
                      }"
                    />
                    <DropdownItem
                      text="Suspender"
                      @click="
                        changeStatus(o.id, ExpenseLineStatus.INACTIVE.key)
                      "
                      v-if="$can('manage', 'mantenimiento_lineas_gasto_suspender') &&
                        o.status === ExpenseLineStatus.ACTIVE.key"
                    />
                    <DropdownItem
                      text="Activar"
                      @click="changeStatus(o.id, ExpenseLineStatus.ACTIVE.key)"
                      v-if="$can('manage', 'mantenimiento_lineas_gasto_suspender') &&
                        o.status === ExpenseLineStatus.INACTIVE.key"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Constants,
  Util,
  Alert,
  PaginationMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
} from "wize-admin";
import { Constants as LocalConstants } from "@/core/utils";
import { ExpenseLineService } from "@/core/services";

import { FilterMenu, FilterMixin } from "wize-admin";

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    FilterMenu,
  },
  data() {
    return {
      list: [],
      ExpenseLineStatus: LocalConstants.ExpenseLineStatus,
      tooltipType: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.ExpenseLineStatus.ACTIVE,
            LocalConstants.ExpenseLineStatus.INACTIVE,
          ],
        },
      ],
    };
  },
  mixins: [PaginationMixin, FilterMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "description",
          search: this.search,
          ...this.filters,
        };
        console.log(params)
        const response = await ExpenseLineService.list(params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        })

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.ExpenseLineStatus);
    },
    changeStatus(id, status) {
      const self = this;
      Alert.dialog(
        "¿Desea cambiar el estado de la línea de gasto?",
        null,
        async function () {
          try {
            await self.$store.dispatch("app/loading", true);
            const resp = (await ExpenseLineService.changeStatus(id, status))
              .payload;

            const obj = self.list.find((o) => {
              return o.id === resp.id;
            });
            if (obj) {
              const index = self.list.indexOf(obj);
              self.$set(self.list, index, resp);
            }
            Alert.success("Estado actualizado correctamente");
          } catch (e) {
            console.error(e);
            Alert.error(e.errors.message);
          } finally {
            await self.$store.dispatch("app/loading", false);
          }
        }
      );
    },
  },
  async created() {
    await this.loadData(this.page, this.pageSize);
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/expense-line-list.styl';
</style>
