<template>
  <aside class="page-sidebar-mobile-container">
    <div
      class="page-sidebar-mobile-overlay"
      :class="{ active: show }"
      @click="toggleMenu(false)"
    ></div>

    <div
      class="page-sidebar-mobile d-flex flex-column d-lg-none"
      :class="{ active: show }"
    >
      <div class="header d-flex align-items-center">
        <b-avatar
          class="avatar"
          src="https://assets.admin.wize.pe/images/dropdown-user-avatar.svg"
        >
        </b-avatar>
        <div class="d-flex flex-column pl-3">
          <span class="user-name">{{ username }}</span>
          <span class="email d-inline-block mt-1">{{ user.email }}</span>
        </div>
      </div>

      <div class="grid mt-2 px-2">
        <router-link
          :to="{ name: 'dashboard' }"
          v-if="$can('manage', 'dashboard')"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{ active: $route.name == 'dashboard' }"
        >
          <span class="menu-icon">
            <i class="ri-home-5-line"></i>
          </span>

          Portada
        </router-link>

        <router-link
          :to="{ name: 'supplier-list' }"
          v-if="$can('manage', 'mantenimiento_proveedores_lista')"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{ active: $route.name == 'supplier-list' }"
        >
          <span class="menu-icon">
            <i class="ri-file-user-line"></i>
          </span>

          Proveedores
        </router-link>

        <button
          type="button"
          v-if="$can('manage', 'mantenimiento_impulsadores_lista') ||
                  $can('manage', 'mantenimiento_lineas_gasto_lista') ||
                  $can('manage', 'mantenimiento_motivos_rechazo_lista') ||
                  $can('manage', 'mantenimiento_proyectos_lista') ||
                  $can('manage', 'mantenimiento_zonales_lista')"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{ active: isConfigRoute }"
          @click="setSelection('config')"
        >
          <span class="menu-icon">
            <i class="ri-edit-line"></i>
          </span>

          Mantenimiento
        </button>

        <button
          type="button"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{
            active: isPaymentsRoute,
          }"
          @click="setSelection('payments')"
        >
          <span class="menu-icon">
            <i class="ri-bank-card-line"></i>
          </span>

          Pagos y comisiones
        </button>

        <router-link
          :to="{ name: 'voucher-list' }"
          v-if="$can('manage', 'comprobantes_lista')"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{ active: $route.name == 'voucher-list' }"
        >
          <span class="menu-icon">
            <i class="ri-coupon-line"></i>
          </span>

          Comprobantes
        </router-link>

        <router-link
          :to="{ name: 'audit-log' }"
          v-if="$can('manage', 'auditoria_lista')"
          class="link d-flex flex-column justify-content-center align-items-center text-white text-center"
          :class="{ active: $route.name == 'audit-log' }"
        >
          <span class="menu-icon">
            <i class="ri-edit-line"></i>
          </span>

          Auditoría
        </router-link>
      </div>

      <div class="menu-list-container">
        <ul class="menu-list list-unstyled" v-if="selection">
          <li v-for="item in getMenuItems" :key="item.text">
            <router-link
              :to="item.route"
              exact-active-class="active"
              v-if="$can('manage', item.role)"
              class="menu-subitem text-decoration-none"
              ><BulletIcon class="fill-white mr-3" />{{
                item.text
              }}</router-link
            >
          </li>
        </ul>
      </div>

      <button
        type="button"
        class="menu-option d-flex align-items-center"
        @click="logout()"
      >
        <LogoutIcon class="fill-white mr-4" />

        <span class="sidebar-item-text">Cerrar sesión</span>
      </button>

      <div
        class="menu-option classic version d-flex justify-content-between align-items-center"
      >
        <span class="text">V{{ version }} {{ year }}</span>
        <p class="my-0">
          Forjado por <a href="https://wize.pe" target="_blank">Wize</a>
        </p>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { Constants as LocalConstants } from "@/core/utils";

import { BulletIcon, LogoutIcon, Helpers } from "wize-admin";

export default {
  name: "PageSidebarMobile",
  data() {
    return {
      LocalConstants,
      version: process.env.PACKAGE_VERSION,
      year: new Date().getFullYear(),
      selection: "",
      menuList: [
        {
          key: "home",
        },
        {
          key: "config",
          list: [
            {
              route: { name: "config-pusher-list" },
              manage: "config-pusher-list",
              text: "Impulsadores",
              role: "mantenimiento_impulsadores_lista",
            },
            {
              route: { name: "config-expense-line-list" },
              manage: "config-expense-line-list",
              text: "Líneas de gasto",
              role: "mantenimiento_lineas_gasto_lista",
            },
            {
              route: { name: "config-reject-reason-list" },
              manage: "config-reject-reason-list",
              text: "Motivos de rechazo",
              role: "mantenimiento_motivos_rechazo_lista",
            },
            {
              route: { name: "config-project-type-list" },
              manage: "config-project-type-list",
              text: "Proyectos",
              role: "mantenimiento_proyectos_lista",
            },
            {
              route: { name: "config-zonal-list" },
              manage: "config-zonal-list",
              text: "Zonales",
              role: "mantenimiento_zonales_lista",
            },
          ],
        },
        {
          key: "payments",
          list: [
            {
              route: { name: "payments-list" },
              manage: "payments-list",
              text: "Lista",
              role: "pagos_lista_lista",
            },
            {
              route: { name: "payments-batch-list" },
              manage: "payments-batch-list",
              text: "Generar IDs de pago",
              role: "pagos_generar_lista",
            },
            {
              route: { name: "payments-made-batch-list" },
              manage: "payments-made-batch-list",
              text: "Cargar abonos",
              role: "pagos_abonos_lista",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.setSelection(this.$route.name);

    if (this.isPaymentsRoute) {
      this.setSelection("payments");
    }

    if (this.isConfigRoute) {
      this.setSelection("config");
    }
  },
  props: {
    show: Boolean,
  },
  components: {
    BulletIcon,
    LogoutIcon,
  },
  watch: {
    "$route.name": function () {
      if (this.currentDevice != "desktop") {
        this.toggleMenu(false);
      }
    },
  },
  methods: {
    ...mapMutations({
      toggleMenu: "app/toggleMenu",
    }),
    setSelection(key) {
      this.selection = key;
    },
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "login" }));
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      isMenuOpen: "app/isMenuOpen",
      getCurrentPlan: "app/getCurrentPlan",
      currentDevice: "app/getCurrentDevice",
      business: "app/getBusiness",
      businesses: "app/getBusinesses",
    }),
    username: function () {
      return Helpers.capitalizeName(this.user.name);
    },
    getMenuItems() {
      let item = this.menuList.find((item) => item.key == this.selection);
      return item ? item.list : [];
    },
    isPaymentsRoute() {
      let routes = ["payments"];
      return (
        routes.some((str) => this.$route.name.includes(str)) ||
        routes.some((str) => this.selection.includes(str))
      );
    },
    isConfigRoute() {
      let routes = ["config"];
      return (
        routes.some((str) => this.$route.name.includes(str)) ||
        routes.some((str) => this.selection.includes(str))
      );
    },
  },
};
</script>

<style lang="stylus">
@import "../../styles/components/layout/page-sidebar-mobile.styl"
</style>
