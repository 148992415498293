<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    size="lg"
    id="approval-modal"
  >
    <div class="modal-header d-flex justify-content-between align-items-center">
      <span class="modal-title">Aceptar comprobante</span>
      <b-button class="modal-close-icon" @click="hideModal" aria-label="Close">
        <i class="ri-close-large-line"></i>
      </b-button>
    </div>

    <section class="modal-body-container">
      <simple-alert
        text="Por favor indique si el cliente tiene una retención:"
        type="light-gray"
        class="mt-2"
      >
        <template slot="icon">
          <span class="modal-icon mr-2">
            <i class="ri-error-warning-line"></i>
          </span>
        </template>
      </simple-alert>

      <form
        action=""
        @submit.prevent="confirm"
        class="custom-form mt-4"
        novalidate
      >
        <div class="form-group d-flex flex-column flex-md-row">
          <label for="rejectReason" class="label">Tipo de pago</label>

          <div class="flex-1">
            <FormSelect
              id="rejectReason"
              v-model="paymentType"
              :items="paymentTypes"
              defaultOption="Elegir motivo de rechazo"
              :showError="$v.paymentType.$error && !$v.paymentType.required"
            />

            <FormError
              v-if="$v.paymentType.$error && !$v.paymentType.required"
              message="Tipo de pago es requerido"
            />
          </div>
        </div>
        <div
          class="form-group d-flex flex-column flex-md-row"
          v-if="payment && payment.type === 1 && payment.paymentAmount > 700"
        >
          <label for="paymentDate" class="label">Porcentaje de detracción *</label>

          <div class="flex-1">
            <input
              id="prefix"
              type="text"
              v-model.trim="detractionPercentage"
              autocomplete="off"
              maxlength="11"
              class="custom-form-control"
              :class="{
                'custom-form-control-error': $v.detractionPercentage.$error,
              }"
            />

            <FormError
              v-if="$v.detractionPercentage.$error && !$v.detractionPercentage.required"
              message="Porcentaje de detracción es requerido"
            />
            <FormError
              v-if="$v.detractionPercentage.$error && !$v.detractionPercentage.integer"
              message="El porcentaje de detracción debe ser un valor numérico"
            />
            <FormError
              v-if="$v.detractionPercentage.$error && !$v.detractionPercentage.minValue"
              message="El porcentaje de detracción debe ser un porcentage válido"
            />
            <FormError
              v-if="$v.detractionPercentage.$error && !$v.detractionPercentage.maxValue"
              message="El porcentaje de detracción debe ser un porcentage válido"
            />
          </div>
        </div>
        <div
          class="form-group d-flex flex-column flex-md-row"
          v-if="payment && payment.type === 1 && payment.paymentAmount > 700"
        >
          <label for="paymentDate" class="label">Monto de detracción *</label>

          <div class="flex-1">
            <input
              id="prefix"
              type="text"
              v-model.trim="detractionAmount"
              autocomplete="off"
              maxlength="11"
              class="custom-form-control"
              :class="{
                'custom-form-control-error': $v.detractionAmount.$error,
              }"
            />

            <FormError
              v-if="$v.detractionAmount.$error && !$v.detractionAmount.required"
              message="Porcentaje de detracción es requerido"
            />
            <FormError
              v-if="$v.detractionAmount.$error && !$v.detractionAmount.integer"
              message="El monto de detracción debe ser un valor numérico"
            />
          </div>
        </div>
        <div class="form-group d-flex flex-column flex-md-row">
          <label for="payedAmount" class="label"
          >El proveedor tiene obligación de retención de terceros.</label
          >

          <div class="flex-1">
            <b-form-checkbox
              id="hasRetention"
              v-model="hasRetention"
              name="check-button"
              switch
              size="lg"
            >
            </b-form-checkbox>
          </div>
        </div>

        <div
          class="form-group d-flex flex-column flex-md-row mb-0"
          v-if="hasRetention"
        >
          <label for="paymentDate" class="label">Monto de retención de terceros *</label>

          <div class="flex-1">
            <input
              id="prefix"
              type="text"
              v-model.trim="retentionAmount"
              autocomplete="off"
              maxlength="11"
              class="custom-form-control"
              :class="{
                'custom-form-control-error': $v.retentionAmount.$error,
              }"
            />

            <FormError
              v-if="$v.retentionAmount.$error && !$v.retentionAmount.required"
              message="Monto de retención es requerido"
            />
            <FormError
              v-if="$v.retentionAmount.$error && !$v.retentionAmount.decimal"
              message="El monto de retención debe ser un valor numérico"
            />
          </div>
        </div>
        <div class="form-group-buttons text-right">
          <button
            class="button button-light"
            type="button"
            @click.prevent="hideModal()"
          >
            Cancelar
          </button>
          <button class="button button-primary" type="submit">Confirmar</button>
        </div>
      </form>
    </section>
  </b-modal>
</template>
<script>
import { FormError, FormSelect, ModalMixin, SimpleAlert } from "wize-admin";
import { decimal, integer, maxValue, minValue, required, requiredIf } from "vuelidate/lib/validators";
import { Constants as LocalConstants } from "@/core/utils";

export default {
  name: "ApprovalModal",
  props: {
    payment: Object,
    default: {}
  },
  data() {
    return {
      hasRetention: false,
      retentionAmount: null,
      detractionPercentage: null,
      detractionAmount: null,
      paymentType: LocalConstants.PaymentType.CREDIT.key,
      paymentTypes: [
        LocalConstants.PaymentType.CREDIT,
        LocalConstants.PaymentType.CASH,

      ]
    };
  },
  mixins: [ModalMixin],
  validations: {
    paymentType: {required},
    detractionPercentage: {required: requiredIf(function () {
        return this.payment && this.payment.type === 1 && this.payment.paymentAmount > 700;
      }), integer, minValue: minValue(1), maxValue: maxValue(100)},
    detractionAmount: {required: requiredIf(function () {
        return this.payment && this.payment.type === 1 && this.payment.paymentAmount > 700;
      }), integer},
    retentionAmount: {
      decimal,
      required: requiredIf(function () {
        return this.hasRetention;
      }),
    },
  },
  watch: {
    detractionPercentage(value) {
      if (value && Number(value) > 0) {
        this.detractionAmount = Math.round((this.payment.paymentAmount * value) / 100);
      }
    },
  },
  components: {
    FormSelect,
    SimpleAlert,
    FormError,
  },
  methods: {
    confirm() {
      this.$v.$touch();
      if (this.$v.$error) return;
      if(!this.payment){
        return
      }
      this.$emit("confirm", this.payment.paymentId, "¿Desea aceptar el comprobante de pago?", {
        status: LocalConstants.PaymentStatus.APPROVED.key,
        hasRetention: this.hasRetention,
        retentionAmount: this.retentionAmount,
        detractionAmount: this.detractionAmount ? this.detractionAmount : 0,
        paymentType: this.paymentType,
        detractionPercentage: this.detractionPercentage ? this.detractionPercentage : 0,
      });
      this.hideModal();
    },
    hideModal() {
      this.hasRetention = false;
      this.retentionAmount = null;
      this.detractionPercentage = null;
      this.detractionAmount = null;
      this.paymentType = LocalConstants.PaymentType.CREDIT.key;
      this.$v.$reset();
      this.$emit("hide");
    },
  },
};
</script>

<style lang="stylus">
@import "~@/core/styles/components/approval-modal.styl";
</style>
