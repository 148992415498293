<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Proveedores</h1>
    </div>
    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar proveedores</div>
              </button>

              <div class="d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold m-0"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold mr-0"
                  v-if="$can('manage', 'mantenimiento_proveedores_descarga_excel')"
                  v-b-tooltip.hover
                  title="Descargar"
                  @click="downloadReport()"
                >
                  <i class="ri-download-2-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>

              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold ml-0"
                v-b-tooltip.hover
                title="Descargar"
                v-if="$can('manage', 'mantenimiento_proveedores_descarga_excel')"
                @click="downloadReport()"
              >
                <i class="ri-download-2-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por n° de documento, nombres y apellidos, celular, correo electrónico, serie de huellero"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />

              <div class="text-right mt-2 mt-md-0">
                <b-button
                  :to="{ name: 'supplier-create' }"
                  variant="none"
                  v-if="$can('manage', 'mantenimiento_proveedores_agregar')"
                  class="button button-primary ml-md-2"
                  >Agregar</b-button
                >
              </div>
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div>
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Razón social</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span>{{ o.companyName }}</span>
                </div>
                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div class="custom-table-mobile-options mt-3">
                <router-link
                  :to="{ name: 'supplier-details', params: { serieId: o.id } }"
                  v-if="$can('manage', 'mantenimiento_proveedores_detalle')"
                >
                  Ver datos
                </router-link>

                <router-link
                  :to="{ name: 'supplier-edit', params: { supplierId: o.id } }"
                  v-if="$can('manage', 'mantenimiento_proveedores_editar')"
                  class="mx-3"
                >
                  Editar
                </router-link>

                <a
                  href=""
                  @click.prevent="
                    changeStatus(o.id, SupplierStatus.INACTIVE.key)
                  "
                  v-if=" $can('manage', 'mantenimiento_proveedores_suspender') &&
                    (o.status === SupplierStatus.ACTIVE.key ||
                    o.status === SupplierStatus.LOCKED.key)
                  "
                  >Suspender</a
                >

                <a
                  href=""
                  class="mx-3"
                  @click.prevent="changeStatus(o.id, SupplierStatus.ACTIVE.key)"
                  v-if="$can('manage', 'mantenimiento_proveedores_suspender') && 
                    o.status === SupplierStatus.INACTIVE.key"
                  >Activar</a
                >

                <a
                  href=""
                  @click.prevent="changeStatus(o.id, SupplierStatus.ACTIVE.key)"
                  v-if="$can('manage', 'mantenimiento_proveedores_suspender') && 
                    o.status === SupplierStatus.LOCKED.key"
                  >Desbloquear</a
                >

                <a
                  href=""
                  class="mx-3"
                  @click.prevent="recoverPassword(o.id)"
                  v-if="$can('manage', 'mantenimiento_proveedores_recuperar_contrasena')"
                  >Recuperar contraseña</a
                >
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >RUC:</span
                    >
                    <span>{{ o.companyId }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Celular:</span
                    >
                    <span>{{ o.mobile }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Correo electrónico:</span
                    >
                    <span>{{ o.email }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Banco:</span
                    >
                    <span>{{ o.bank ? o.bank.name : "-" }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Impulsadores:</span
                    >
                    <span>{{ o.pushersCount }}</span>
                  </li>

                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                      >Estado:</span
                    >
                    <span :class="`badge ${getStatus(o.status).color}`">{{
                      getStatus(o.status).label
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
            <tr>
              <th>RUC</th>
              <th>Razón Social</th>
              <th>Celular</th>
              <th>Correo electrónico</th>
              <th>Banco</th>
              <th>Impulsadores</th>
              <th>Estado</th>
              <th class="pr-md-3" 
              v-if="$can('manage', 'mantenimiento_proveedores_detalle') || 
                        $can('manage', 'mantenimiento_proveedores_editar') || 
                        $can('manage', 'mantenimiento_proveedores_recuperar_contrasena') ||
                        $can('manage', 'mantenimiento_proveedores_suspender')" >Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="cell-center pl-3">{{ o.companyId || '-' }}</td>
              <td>{{ o.companyName || '-' }}</td>
              <td class="cell-center">{{ o.mobile || '-' }}</td>
              <td>{{ o.email || '-' }}</td>
              <td class="cell-center">{{ o.bank ? o.bank.name : '-' }}</td>
              <td class="cell-center">{{ o.pushersCount || '-' }}</td>
              <td class="cell-center pl-md-2">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>
              <td class="cell-center d_option pr-md-2 position-relative" 
              v-if="$can('manage', 'mantenimiento_proveedores_detalle') || 
                        $can('manage', 'mantenimiento_proveedores_editar') || 
                        $can('manage', 'mantenimiento_proveedores_recuperar_contrasena') ||
                        $can('manage', 'mantenimiento_proveedores_suspender')">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Ver datos"
                      v-if="$can('manage', 'mantenimiento_proveedores_detalle')"
                      :to="{
                        name: 'supplier-details',
                        params: { supplierId: o.id },
                      }"
                    />
                    <DropdownItem
                      text="Editar"
                      v-if="$can('manage', 'mantenimiento_proveedores_editar')"
                      :to="{
                        name: 'supplier-edit',
                        params: { supplierId: o.id },
                      }"
                    />
                    <DropdownItem
                      text="Suspender"
                      @click="changeStatus(o.id, SupplierStatus.INACTIVE.key)"
                      v-if="
                        $can('manage', 'mantenimiento_proveedores_suspender') &&
                        (o.status === SupplierStatus.ACTIVE.key ||
                        o.status === SupplierStatus.LOCKED.key)
                      "
                    />
                    <DropdownItem
                      text="Activar"
                      @click="changeStatus(o.id, SupplierStatus.ACTIVE.key)"
                      v-if="
                        $can('manage', 'mantenimiento_proveedores_suspender') &&
                        o.status === SupplierStatus.INACTIVE.key"
                    />
                    <DropdownItem
                      text="Desbloquear"
                      @click="changeStatus(o.id, SupplierStatus.ACTIVE.key)"
                      v-if="$can('manage', 'mantenimiento_proveedores_suspender') && 
                      o.status === SupplierStatus.LOCKED.key"
                    />
                    <DropdownItem
                      text="Recuperar contraseña"
                      v-if="$can('manage', 'mantenimiento_proveedores_recuperar_contrasena')"
                      @click="recoverPassword(o.id)"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Constants,
  Util,
  Alert,
  FilterMenu,
  FilterMixin,
  PaginationMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
} from "wize-admin";

import { Constants as LocalConstants } from "@/core/utils";
import { BankService, SupplierService } from "@/core/services";
import fileDownload from "js-file-download";

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    FilterMenu,
  },
  data() {
    return {
      list: [],
      SupplierStatus: LocalConstants.SupplierStatus,
      tooltipType: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.SupplierStatus.ACTIVE,
            LocalConstants.SupplierStatus.INACTIVE,
            LocalConstants.SupplierStatus.LOCKED,
          ],
        },
        {
          label: "Banco",
          key: "banks",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Tipo de gestión contable",
          key: "managementType",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.ManagementType.ON_THE_GROUP,
            LocalConstants.ManagementType.INDIVIDUAL,
          ],
        },
      ],
    };
  },
  mixins: [PaginationMixin, FilterMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);
        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "companyName",
        };
        const data = { search: this.search, ...this.filters };
        const response = await SupplierService.list(data, params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.SupplierStatus);
    },
    changeStatus(supplierId, status) {
      const self = this;
      Alert.dialog(
        "¿Desea cambiar el estado del proveedor?",
        null,
        async function () {
          try {
            await self.$store.dispatch("app/loading", true);
            const resp = (
              await SupplierService.changeStatus(supplierId, status)
            ).payload;

            const obj = self.list.find((o) => {
              return o.id === resp.id;
            });
            if (obj) {
              const index = self.list.indexOf(obj);
              self.$set(self.list, index, resp);
            }
            Alert.success("Estado actualizado correctamente");
          } catch (e) {
            console.error(e);
            Alert.error(e.errors.message);
          } finally {
            await self.$store.dispatch("app/loading", false);
          }
        }
      );
    },
    async recoverPassword(supplierId) {
      try {
        await this.$store.dispatch("app/loading", true);
        await SupplierService.recoverPassword(supplierId);
        Alert.success(
          "Se ha enviado un correo electrónico al proveedor para que pueda restablecer su contraseña"
        );
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadReport() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        const response = await SupplierService.download(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    await this.loadData(this.page, this.pageSize);
    const banks = (await BankService.list()).payload;
    const bankOption = this.filterOptions.find((o) => o.key === "banks");
    banks.forEach((b) => bankOption.options.push({ key: b.id, label: b.name }));
  },
};
</script>

<style lang="stylus">
@import '../Styles/supplier-list';
</style>
